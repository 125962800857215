// Vendors
import Vue from 'vue';
import $ from "jquery";
// Components
import drawer from './components/drawer.vue';
import stickyTop from './components/stickyTop.vue';
import slick from 'vue-slick';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue'

class App {

  constructor() {
    // jQuery enkel nodig voor Slick Slider / Royal Slider
    window.jQuery = $;
    window.$ = $;
  }

  init() {

    Vue.config.productionTip = false;

    new Vue({
      el: '#app',
      delimiters: ['<%', '%>'],
      components: {
        'drawer': drawer,
        'sticky-top': stickyTop,
        // Vendors
        'slick': slick,
        SweetModal,
      },
      methods: {
        openMenu() {
          if (this.$refs.LeftDrawer.active) {
            this.$refs.LeftDrawer.close();
          } else {

            this.$refs.LeftDrawer.open();
          }
        },
        openVideoModal() {
          this.$refs.modal.open();
        }
      }
    });
  }
}

const app = new App();
app.init();
