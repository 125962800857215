var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enabled
    ? _c("section", [
        _c(
          "aside",
          { ref: "element", staticClass: "sidebar", style: _vm.style },
          [_vm._t("default")],
          2
        ),
        _vm._v(" "),
        _c("div", { ref: "overlay", staticClass: "overlay" })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }